<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateMenuModal()">
        <PlusOutlined />新增
      </a-button>

      <a-button style="float: right" type="primary" @click="syncNav">同步栏目</a-button>
    </div>

    <a-table :columns="columns" :data-source="data" rowKey="name" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'title'">
          <span v-if="record.module == 10">{{ record.title }}</span>
          <a-button v-else type="dashed" size="small">{{
            record.title
          }}</a-button>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="addChild(record)">添加子菜单</a>
            <a-divider type="vertical" />
            <a @click="editMenu(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="visible" title="添加" @ok="handleSubmit" @cancel="onCancel" :width="720">
    <a-form ref="formRef" v-bind="layout" :model="formState">
      <a-form-item has-feedback label="名称" name="name">
        <a-input v-model:value="formState.name" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item has-feedback label="路径" name="path">
        <a-input v-model:value="formState.path" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item has-feedback label="上级菜单" name="roles">
        <a-tree-select v-model:value="selectKeys" show-search style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" allow-clear :tree-data="treeData">
        </a-tree-select>
      </a-form-item>

      <a-form-item has-feedback label="状态" name="is_show">
        <a-switch v-model:checked="checked" checked-children="正常" un-checked-children="禁用" />
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
        <a-input class="mini-input" type="number" v-model:value="formState.sort" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  ref,
  onMounted,
  createVNode,
  reactive,
  watch,
} from "vue";
import * as api from "@/views/manage/api";
import { message, Modal } from "ant-design-vue";
import { createRouteByList } from "@/router/navigation";
import routes from "@/router/routes";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const columns = [
  {
    title: "ID",
    dataIndex: "menu_id",
    width: "10%",
  },
  {
    title: "菜单标题",
    dataIndex: "title",
    width: "15%",
  },
  {
    title: "标识",
    dataIndex: "name",
    width: "15%",
  },
  {
    title: "菜单路径",
    dataIndex: "path",
    width: "10%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
  },
  setup() {
    const formRef = ref();
    const formRef1 = ref();
    const selectKeys = ref("0");
    const checked = ref(true);
    const formState = reactive({
      name: "",
      path: "",
      sort: "",
      parent_id: 0,
      is_show: 1,
      menu_id: 0,
    });
    const formApiState = reactive({
      name: "",
      api_ids: [],
      menu_id: 0,
    });
    const replaceFields = {
      children: "children",
      title: "title",
      key: "name",
      parent_id: "parent_code",
    };

    const visible = ref(false);

    const onApiCancel = () => {};

    const data = ref([]);
    const handleRefresh = () => {
      api.menu_list().then((res) => {
        data.value = res.data.list;
      });
    };
    const treeData = ref([]);

    const getTreeMenuData = () => {
      api.tree_list().then((res) => {
        treeData.value = res.data;
      });
    };
    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          api
            .del({
              menu_id: record.menu_id,
            })
            .then((res) => {
              message.success(res.message);
              handleRefresh();
            });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };
    const showCreateMenuModal = () => {
      visible.value = true;
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (formState.menu_id) {
            api.update(formState).then(
              (res) => {
                message.success(res.message);
                visible.value = false;
                // 通知父组件刷新列表
                formRef.value.resetFields();
                handleRefresh();
              },
              (error) => {
                message.error(error);
              }
            );
          } else {
            api.create(formState).then(
              (res) => {
                message.success(res.message);
                visible.value = false;
                // 通知父组件刷新列表
                formRef.value.resetFields();
                handleRefresh();
              },
              (error) => {
                message.error(error);
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {};
    const addChild = (record) => {
      formState.parent_id = record.menu_id;
      selectKeys.value = record.menu_id;
      visible.value = true;
    };

    const editMenu = (record) => {
      api
        .info({
          menu_id: record.menu_id,
        })
        .then((res) => {
          selectKeys.value = res.data.parent_id;
          formState.parent_id = res.data.parent_id;
          formState.menu_id = res.data.menu_id;
          formState.name = res.data.name;
          formState.path = res.data.path;
          formState.sort = res.data.sort;
          formState.is_show = res.data.is_show;
          visible.value = true;
        });
    };
    onMounted(() => {
      handleRefresh();
      getTreeMenuData();
    });
    watch(selectKeys, () => {
      formState.parent_id = selectKeys.value;
    });
    watch(checked, () => {
      formState.is_show = checked.value ? 1 : 0;
    });

    const checkedKeys = ref([]);

    watch(checkedKeys, () => {
      formApiState.api_ids = checkedKeys.value.join(",");
    });

    // 获取本地固定路由下所有路由信息
    const menus = createRouteByList(routes[0].children);
    // const menus = [];
    console.log(menus)
    const syncNav = () => {
      api.sync_nav({
          data: menus,
        })
        .then((res) => {
          message.success(res.message);
        });
    };

    return {
      columns,
      data,
      handleRefresh,
      showDeleteConfirm,
      layout,
      formState,
      visible,
      treeData,
      showCreateMenuModal,
      selectKeys,
      handleSubmit,
      onCancel,
      checked,
      formRef,
      formRef1,
      addChild,
      editMenu,
      onApiCancel,
      formApiState,
      checkedKeys,
      replaceFields,
      syncNav,
    };
  },
});
</script>